<template>
  <div>
    <Teleport to="#header-buttons">
      <div>
        <b-button
          id="device-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="ml-2"
          variant="primary"
          @click="createCampaignForThisVenue"
        >
          Book Now
        </b-button>
      </div>
    </Teleport>
    <b-card>
      <VenueDashboard
        ref="venue-dashboard"
        :single-venue="true"
      />
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import Teleport from 'vue2-teleport'
import VenueDashboard from './VenueDashboard.vue'

export default {
  components: {
    VenueDashboard,
    BCard,
    BButton,
    Teleport,
  },
  methods: {
    createCampaignForThisVenue() {
      this.$refs['venue-dashboard'].goToCreateCampaign()
    },
  },
}
</script>
